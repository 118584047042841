
:root{
    --iconColor25: 0px;
    --iconColor40: 0px;

    --surfacePrimary: #000000;
    --surfaceSecondary: #121416;
    --surfaceTertiary: #1C1E22;

    --contentPrimary: #FFFFFF;
    --contentSecondary: #FFFFFF80;
    --contentTertiary: #FFFFFF40;
    --contentNegative: #FF002B;
    --contentPositive: #00CC44;
}


$iconColor25: var(--iconColor25);
$iconColor40: var(--iconColor40);

$dark1: var(--surfacePrimary);
$dark2: var(--surfaceSecondary);
$dark3: var(--surfaceTertiary);
$dark4: var(--contentTertiary);
$dark5: var(--contentSecondary);

$light: var(--contentPrimary);
$light_opacity50: var(--contentSecondary);
$light_opacity25: var(--contentTertiary);
$red: var(--contentNegative);
$green: var(--contentPositive);

