@import "./variables.scss";

.AdvancedChart {

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    .chartContainer{
        background-color: $dark1!important;
    }

    cq-context {
        background-color: $dark1;
        height: 100vh;
        width: 100vw;
    }

    .ciq-nav,
    .ciq-footer {
        border-color: $dark2;
    }

    .stx-data.tfc-gain-amount:not(.tfc-gain-custom){
        display: none;
    }

    .stx-limit-order.tfc-cancel{
        height: 160px;
    }

    .stx-panel-side.stx-trade-panel.active{
            border-left: 1px solid $dark4;
        }

    .stx-limit-order{
        height: 110px;
    }

    cq-loader {
        height: 50px;
        width: 50px;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        background-image: url("loader.svg");
        background-size: cover;
        z-index: 10000;
    }

    cq-show-range * {
        color: $light;
    }

    cq-share-button,
    .stx-subholder,
    cq-clickable[cq-selector="cq-lookup-dialog"],
    .chart-theme,
    .chart-locale,
    .ciq-preferences cq-menu-dropdown-section cq-separator:last-of-type,
    .y-axis-preferences cq-heading,
    .chartControls {
        display: none !important;
    }
    .stx-bracket, .stx-strangle, .stx-straddle{
        display: none!important;
    }


.stx-panel-module.stx-trade-current{
        display: none;
    }

    .tfc-lots-position.tfc-current-symbol{
        display: none;
    }

    .stx-data.tfc-gain-percent{
        display: none;
    }

    .stx-panel-module.stx-trade-account{
        border-bottom: 1px solid $dark4;
        border-top: 1px solid $dark4;
    }

    .tfc-positions-view.summary, .tfc-positions-view.performance{
        display: none!important;
    }

    .stx-current-position{
        // masquer le 5eme enfant de <tr<
        th:nth-child(5), td:nth-child(5){
            display: none!important;
        }


    }
    .stx-section.stx-holder{
        &>cq-scroll{
            height: 100px!important;
            max-height: 100px!important;
        }
    }

    .stx-section{
        background-color: $dark1;
    }

    .tfc.create-order.stx-limit-order.new-sell-order.with-stop.with-limit{
        .col1{
            display: none;
        }
        .col2{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-row: 4px;
            float: none;
            width: auto;

            .stx-select{
                width: 100%;
            }

        }

    }

    .inputTemplate.gain{
        display: none;
    }

    .stx-panel-module.stx-trade-account{
        tr:nth-child(2),
        tr:nth-child(3),
        tr:nth-child(5)
        {
            display: none!important;
        }
    }

    .tfc-lots-position.sym{
        display: none;
    }

    .stx-head-bar{
        margin-top: 8px;
    }


      .stx-panel-module.stx-trade-positions{
        padding-bottom: 20px;

    }



    cq-menu.cq-comparison-new {
        display: none;
    }

    cq-clickable[role=button]:hover>*:not(.hide-outline) {
        border: none !important;
    }

    // .ciq-toggles.trade-toggles{
    //     display: none;
    // }

    cq-themes-builtin {

        cq-item:nth-child(2),
        cq-item:nth-child(3) {
            display: none;
        }
    }

    cq-theme-custom {
        &:nth-child(2) {
            cq-close {
                display: none;
            }
        }
    }



    cq-toggle.active,
    cq-clickable[role=button].active {
        border-color: $dark2;
    }

    cq-heading {
        color: red;
    }

    cq-drawing-palette {
        background-color: $dark1;

        cq-item {
            &:hover {
                label {
                    background-color: $green;
                    color: white;
                }

            }
        }
    }

    .ciq-chart {
        box-shadow: none;
    }

    cq-drawing-settings {
        background-color: $dark1;
    }

    cq-drawing-settings[docked=true] .drawing-settings-wrapper {
        box-sizing: border-box;
        border-right: 1px solid $dark2;
    }

    .ciq-active>.ciq-switch:before,
    .ciq-switch:checked {
        background-color: $green;
    }

    cq-heading[cq-filter] .searchFilter input[type=search] {
        border-color: $green;
        background-color: $dark1;

    }

    .ciq-active .ciq-radio span:after,
    .ciq-radio.ciq-active span:after {
        background-color: $green;
    }

    cq-hu-dynamic {
        color: $green;
    }

    path.ciq-hu-stroke {
        fill: $dark5;
    }

    cq-chart-title {
        display: none;
    }

    cq-hu-static {
        left: 10px;
        background-color: $dark3;

        cq-hu-volume,
        cq-hu-price,
        cq-hu-open,
        cq-hu-close,
        cq-hu-high,
        cq-hu-low,
        cq-volume-rollup {
            color: $dark1;
        }
    }

    .chartSize .stx-chart-control-button.active {
        border-color: $dark1;
    }

    .ciq-data-table-container {
        background-color: $dark1;
        color: $dark1;

        button {
            background-color: $dark1;
            color: $dark1;
        }

        .additionalColumns {
            background-color: $dark1;
            color: $dark1;

            &:hover {
                background-color: $dark4;
                color: $dark1;
            }
        }
    }

    cq-attrib-container {
        background-color: $dark1;
        color: $dark5;
        border-radius: 0;
    }

    .ciq-toggles:not(.sidenav-toggle):after {
        display: none;
    }

    cq-chartcontrol-group {
        &>.symbol-search {
            display: none;
        }
    }

    cq-context.full-screen {
        cq-chartcontrol-group {
            top: 10px !important;
            left: 280px;
        }
    }

    cq-share-button {
        display: none;
    }

    .stx_chart_controls .stx-tooltip {
        background-color: $green;
    }

    .hu-tooltip,
    .mSticky {
        border-color: $green;
    }

    cq-menu-dropdown {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid $dark2;
        border-top: none;
        box-shadow: none;
        background-color: $dark1;

        cq-item {
            border-radius: 4px;

            &:hover {
                background-color: $dark2;
                color: $dark1;
            }
        }
    }

    cq-menu {
        background-color: $dark1;
        border-radius: 0;
        box-shadow: none;

        * {
            color: $light;
        }

        &:hover {
            background-color: $dark1;
            outline: none;
            border-color: transparent;

            * {
                color: $light;
            }
        }
    }

    html:not([ciq-last-interaction=touch]) cq-item:hover,
    html[ciq-last-interaction=touch] cq-item:active,
    [native-tabbing] cq-item:focus,
    cq-item[cq-focused] {
        background-color: $dark1;
        outline: none;
        border-color: transparent;

        * {
            color: $dark1;
        }
    }

    cq-tooltip {
        background-color: $green;
        color: white !important;
        border-radius: 2px;
        border-color: transparent !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    }

    cq-study-legend {
        cq-section-dynamic {
            background-color: $dark1;

            cq-item {
                box-sizing: border-box;
            }
        }

        top: 50px !important;
        color: $dark1;
    }

    cq-separator {
        border-color: $dark2;
    }

    cq-study-legend cq-section-dynamic {
        border-top: none;
    }

    cq-show-range>div:active {
        border-color: $green;
    }

    cq-drawing-palette .ciq-tool[cq-tool-group*=favorite] .fav-marker,
    cq-drawing-palette .ciq-tool:active .icon,
    cq-drawing-palette .ciq-tool.active .icon {
        background-image: url("/img/chart/cq-drawing-palette-arlequin.svg");
    }

    cq-drawing-palette.list .mini-widget-group .ciq-mini-widget[cq-view=list] .icon,
    cq-drawing-palette.grid .mini-widget-group .ciq-mini-widget[cq-view=grid] .icon,
    cq-drawing-palette .ciq-mini-widget.active .icon,
    cq-drawing-palette .ciq-mini-widget:active .icon {
        background-image: url("/img/chart/cq-sprite-palette-arlequin.svg");
    }

    cq-drawing-palette.list .tool-group .ciq-tool .icon,
    .break-sm cq-drawing-palette .tool-group .ciq-tool .icon,
    .break-height-sm cq-drawing-palette .tool-group .ciq-tool .icon {
        top: -5px;
    }

    cq-drawing-palette.list .tool-group .ciq-tool.active label,
    .break-sm cq-drawing-palette .tool-group .ciq-tool.active label,
    .break-height-sm cq-drawing-palette .tool-group .ciq-tool.active label {
        color: $green;
    }










    cq-color-picker[cq-active=true],
    cq-menu-dropdown {
        z-index: 100000;

        * {
            box-sizing: content-box;
        }
    }



    cq-chartcontrol-group {
        display: none !important;
    }


    .stx-holder {
        border-left: 1px solid $dark2;
    }

    .cq-dialogs[cq-active=true] {
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: transparent;
        z-index: 10000;
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        box-sizing: content-box;

        * {
            box-sizing: content-box;
        }
    }


    .ciq-btn,
    .ciq-btn-negative,
    .annotationCancel,
    .annotationSave {
        color: $dark1 !important;
        background-color: $dark1;

    }

    html:not([ciq-last-interaction=touch]) .ciq-btn:hover,
    html:not([ciq-last-interaction=touch]) .ciq-btn-negative:hover,
    html:not([ciq-last-interaction=touch]) .annotationCancel:hover,
    html:not([ciq-last-interaction=touch]) .annotationSave:hover {
        color: $green !important;
    }

    .stx-market,
    .stx-sell,
    .stx-buy,
    .stx-short {
        a {
            background-position-x: calc(-37px + var(--iconColor40))!important;
        }
    }

    .ciq-switch:before {
        background-color: $dark4;
    }


    span[ciq-menu-icon] {
        background-position-x: -25px
    }

    cq-plus {
        background-position-y: -48px
    }

    .ciq-icon.ciq-close {
        &::after {
            background-position-y: -48px
        }
    }

    .ciq-menu.ciq-preferences {
        span {
            background-position-y: calc(-50px + var(--iconColor25))
        }
    }

    cq-toggle>*:not(cq-text) {
        background-position-y: calc(-50px + var(--iconColor25))
    }

    cq-drawing-palette .mini-widget-group .ciq-mini-widget[cq-view=grid] .icon {
        background-position-x: -25px
    }





    .break-sm .ciq-menu.ciq-views>span:after,
    .break-md .ciq-menu.ciq-views>span:after,
    .break-sm .ciq-menu.ciq-studies>span:after,
    .break-md .ciq-menu.ciq-studies>span:after {
        background-position-y: calc(-50px + var(--iconColor25))
    }



    cq-toolbar-settings div[cq-toolbar-action=restore],
    .ciq-shortcut-button {
        background-position-y: -50px;
    }

    cq-comparison-item .ciq-close,
    .ciq-edit {
        background-position-y: -50px;
    }

    cq-study-legend {
        background-color: var(--clair3);
    }

}

    .iconColor-dark{
        cq-drawing-palette .ciq-mini-widget .icon,
        cq-drawing-palette.list .mini-widget-group .ciq-mini-widget[cq-view=list] .icon,
        cq-drawing-palette.grid .mini-widget-group .ciq-mini-widget[cq-view=grid] .icon,
        cq-drawing-palette .ciq-mini-widget.active .icon,
        cq-drawing-palette .ciq-mini-widget:active .icon,
        cq-drawing-settings .mini-widget-group .ciq-mini-widget .icon {
            background-image: url("/img/chart/cq-sprite-palette-white.svg");
        }

            cq-drawing-palette .ciq-tool .icon,
    cq-drawing-palette .ciq-tool .fav-marker,
    cq-drawing-settings .ciq-icon-btn.cq-icon-gear {
        background-image: url("/img/chart/cq-drawing-palette-white.svg");
    }

        cq-toolbar-settings div[cq-toolbar-action=save] {
        background-image: url("/img/chart/ciq-save-white.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=none] {
        background-image: url("/img/chart/ew-none-white.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=parentheses] {
        background-image: url("/img/chart/ew-parentheses-white.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=enclosed] {
        background-image: url("/img/chart/ew-circle-white.svg");
    }



    }

    .iconColor-light{
        cq-drawing-palette .ciq-mini-widget .icon,
        cq-drawing-palette.list .mini-widget-group .ciq-mini-widget[cq-view=list] .icon,
        cq-drawing-palette.grid .mini-widget-group .ciq-mini-widget[cq-view=grid] .icon,
        cq-drawing-palette .ciq-mini-widget.active .icon,
        cq-drawing-palette .ciq-mini-widget:active .icon,
        cq-drawing-settings .mini-widget-group .ciq-mini-widget .icon {
            background-image: url("/img/chart/cq-sprite-palette-black.svg");
        }

                    cq-drawing-palette .ciq-tool .icon,
    cq-drawing-palette .ciq-tool .fav-marker,
    cq-drawing-settings .ciq-icon-btn.cq-icon-gear {
        background-image: url("/img/chart/cq-drawing-palette-black.svg");
    }

        cq-toolbar-settings div[cq-toolbar-action=save] {
        background-image: url("/img/chart/ciq-save-black.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=none] {
        background-image: url("/img/chart/ew-none-black.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=parentheses] {
        background-image: url("/img/chart/ew-parentheses-black.svg");
    }

    cq-wave-parameters .ciq-icon-btn.ciq-btn[decoration=enclosed] {
        background-image: url("/img/chart/ew-circle-black.svg");
    }

    }